<template>
  <main>
    <content-page-header>
      <div class="hdg-tab">
        <common-page-title title="アカウント情報" />
        <tab
          v-if="servicePlanId == 1"
          tab-count="1"
          :tab-name="state.tabName"
          @tabClick="tabClick"
        />
      </div>
    </content-page-header>
    <div class="content-body">
      <panel-body :class="state.tabBody === 1 ? 'force-pb-0' : ''">
        <div class="edit-profile-panel" v-if="state.tabBody === 0">
          <login-user-infomation />
          <div class="edit-profile border-t border-t--decoration">
            <section>
              <common-block-title title="ユーザー情報" sub-title="" />
              <form id="editUserForm" class="edit-form">
                <template v-if="!isEnterprise">
                  <label class="form-function">
                    <div class="form-input-label">貴社名</div>
                    <div class="w-full">
                      <input
                        type="text"
                        placeholder="貴社名"
                        v-model="state.editUser.company_name"
                      />
                      <div
                        class="error-message"
                        v-for="(error, index) in state.errors.company_name"
                        :key="index"
                      >
                        <icon name="error" class="icon" />{{ error }}
                      </div>
                    </div>
                  </label>
                </template>
                <label class="form-function">
                  <div class="form-input-label">
                    お名前<sup class="type-required">*</sup>
                  </div>
                  <div class="w-full">
                    <input
                      type="text"
                      placeholder="お名前"
                      v-model="state.editUser.name"
                    />
                  </div>
                </label>
                <label class="form-function">
                  <div class="form-input-label">
                    お名前（カナ）<sup class="type-required">*</sup>
                  </div>
                  <div class="w-full">
                    <input
                      type="text"
                      placeholder="お名前（カナ）"
                      v-model="state.editUser.name_kana"
                    />
                    <div
                      class="error-message"
                      v-for="(error, index) in state.errors.name_kana"
                      :key="index"
                    >
                      <icon name="error" class="icon" />{{ error }}
                    </div>
                  </div>
                </label>
                <label class="form-function">
                  <div class="form-input-label">
                    メールアドレス<sup class="type-required">*</sup>
                  </div>
                  <div
                    class="w-full with-icon"
                    :class="state.addressCheck ? 'is-active' : ''"
                  >
                    <input
                      type="email"
                      placeholder="会社名@gmail.com"
                      v-model="state.editUser.email"
                      :readonly="!state.addressCheck"
                    />
                    <div
                      class="error-message"
                      v-for="(error, index) in state.errors.email"
                      :key="index"
                    >
                      <icon name="error" class="icon" />{{ error }}
                    </div>
                    <span class="input-icon" @click="clickAddressCheck">
                      <icon
                        v-if="!state.addressCheck"
                        name="input-pencil-icon"
                        class="icon"
                      />
                      <icon
                        v-else
                        name="input-pencil-icon-active"
                        class="icon"
                      />
                    </span>
                  </div>
                </label>
                <template v-if="!isEnterprise">
                  <div class="form-function items-baseline">
                    <div class="form-input-label" v-if="!isEnterprise">
                      メール通知
                    </div>
                    <div class="w-full">
                      <input
                        type="checkbox"
                        v-model="state.editUser.is_receive_mail"
                        class="form-input-check"
                        id="delivery"
                      />
                      <label for="delivery">
                        株式会社アイレップからの有益な情報メールを受け取る
                      </label>
                      <p class="main-campaign">キャンペーンや新規機能追加、講座情報などをお知らせします</p>
                    </div>
                  </div>
                </template>
              </form>
            </section>
          </div>
          <div class="edit-psaaword border-t border-t--decoration">
            <section>
              <common-block-title title="パスワードの変更" sub-title="" />
              <form id="editPasswordForm" class="edit-form">
                <label class="form-function">
                  <div class="form-input-label">現在のパスワード</div>
                  <div class="w-full with-icon">
                    <input
                      :type="state.oldPasswordCheck ? 'text' : 'password'"
                      placeholder="現在のパスワード"
                      v-model="state.editUser.old_password"
                    />
                    <span
                      class="input-icon"
                      @click="state.oldPasswordCheck = !state.oldPasswordCheck"
                    >
                      <icon
                        v-if="!state.oldPasswordCheck"
                        name="input-eye-icon"
                        class="icon"
                      />
                      <icon v-else name="input-eye-icon-active" class="icon" />
                    </span>
                    <div
                      class="error-message"
                      v-for="(error, index) in state.errors.old_password"
                      :key="index"
                    >
                      <icon name="error" class="icon" />{{ error }}
                    </div>
                  </div>
                </label>
                <label class="form-function">
                  <div class="form-input-label">新パスワード</div>
                  <div class="w-full with-icon">
                    <input
                      :type="state.newPasswordCheck ? 'text' : 'password'"
                      placeholder="新パスワード"
                      v-model="state.editUser.new_password"
                    />
                    <span
                      class="input-icon"
                      @click="state.newPasswordCheck = !state.newPasswordCheck"
                    >
                      <icon
                        v-if="!state.newPasswordCheck"
                        name="input-eye-icon"
                        class="icon"
                      />
                      <icon v-else name="input-eye-icon-active" class="icon" />
                    </span>
                    <div
                      class="error-message"
                      v-for="(error, index) in state.errors.new_password"
                      :key="index"
                    >
                      <icon name="error" class="icon" />{{ error }}
                    </div>
                  </div>
                </label>
              </form>
            </section>
          </div>
          <div
            class="border-t border-t--decoration"
            v-if="
              !state.andDigitalUserAuthortyIds.includes(
                state.editUser.user_authority_id
              )
            "
          >
            <div
              class="form-function justify-start"
              v-if="state.customer.end_at"
            >
              <p class="form-input-label">解約予定日</p>
              <p class="form-input-label">
                {{ display.toDate(state.customer.end_at) }}
              </p>
            </div>
            <div class="form-function justify-start" v-else>
              <p class="form-input-label">解約はこちら</p>
              <p
                class="decoration-button"
                @click="$router.push({ path: '/cancel/input' })"
              >
                解約する
              </p>
            </div>
          </div>
          <div class="submit-area">
            <button
              class="submit-area-button submit-area-button--save"
              :disabled="state.editUser.checkRequire"
              @click="submit"
            >
              Save
            </button>
          </div>
        </div>
        <div class="edit-profile-panel" v-if="state.tabBody === 1">
          <div class="payment-header">
            <common-block-title title="決済履歴" />
            <div class="payment-action">
              <button
                @click="$router.push({ path: '/profile/update/payment-info' })"
                class="payment-action-button payment-action-button--black"
              >
                お支払い情報確認<icon name="arrow" class="icon-arrow"></icon>
              </button>
              <button
                @click="downloadAll(paymentHistoryList)"
                class="payment-action-button payment-action-button--grey"
              >
                Download All<icon name="download" class="icon-download"></icon>
              </button>
            </div>
          </div>
          <payment-history-table
            :paymentHistoryList="paymentHistoryList"
            @toggleAccordion="toggleAccordion"
            @download="download"
          />
        </div>
      </panel-body>
    </div>
    <mydialog
      :isShowAlert="state.showAlert"
      title="Saved"
      subTitle="保存完了"
      message="変更を保存しました"
      messageColor="black"
      submit="Topページへ戻る"
      @cancelClick="state.showAlert = false"
      @submitClick="$router.push({ path: '/' })"
      @closeDialog="state.showAlert = false"
    />
  </main>
</template>

<script>
import { defineComponent, reactive, ref, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";
import http from "@/utils/http";
import display from "@/utils/display";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import Tab from "@/components/CommonTab/tab.vue";
import LoginUserInfomation from "@/components/loginUserInfomation.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import mydialog from "@/components/CommonDialog/CommonDialog.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import PaymentHistoryTable from "@/views/profile-update/components/PaymentHistoryTable";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "editUserPassView",

  components: {
    contentPageHeader,
    CommonPageTitle,
    Tab,
    LoginUserInfomation,
    CommonBlockTitle,
    mydialog,
    PanelBody,
    PaymentHistoryTable,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      tabName: [
        {
          en: "PROFILE",
          ja: "プロフィール",
          isActive: true,
          methods: "profile",
          href: "/profile/update",
        },
        {
          en: "PAYMENT",
          ja: "お支払い",
          isActive: false,
          methods: "payment",
          href: "/profile/update",
        },
      ],
      editUser: {
        company_name: "",
        name: "",
        name_kana: "",
        email: "",
        industry_type_id: 0,
        user_authority_id: 0,
        old_password: "",
        new_password: "",
        checkRequire: true,
        is_receive_mail: false
      },
      customer: {
        end_at: "",
      },
      nowEmail: "",
      oldPasswordCheck: false,
      newPasswordCheck: false,
      addressCheck: false,
      errors: {
        name_kana: [],
        email: [],
        industry_type_id: [],
        old_password: [],
        new_password: [],
      },
      showAlert: false,
      tabBody: 0,
      andDigitalUserAuthortyIds: [1, 2, 3, 4, 6, 7, 8],
    });
    const isLogin = computed(() => store.state.user.isLogin);
    const isEnterprise = computed(() => store.state.user.isEnterprise);
    const servicePlanId = computed(() => store.state.user.servicePlanId);
    const paymentHistoryList = ref({});

    window.scrollTo(0, 0);

    if (store._state.data.payment === 1) {
      state.tabBody = 1;
      state.tabName[0].isActive = false;
      state.tabName[1].isActive = true;
      store.dispatch("changePaymentTab", 0);
    }

    // method
    const tabClick = (n) => {
      state.tabName.forEach((e) => {
        e.isActive = false;
      });
      state.tabName[n].isActive = true;
      state.tabBody = n;
    };

    // api 取得
    const fetchUsersMe = () => {
      return http
        .get("/api/users/me")
        .then((res) => {
          state.editUser.company_name = res.data.company_name;
          state.editUser.name = res.data.name;
          state.editUser.name_kana = res.data.name_kana;
          state.editUser.email = res.data.email;
          state.editUser.industry_type_id = res.data.industry_type_id;
          state.editUser.user_authority_id = res.data.user_authority_id;
          state.nowEmail = res.data.email;
          state.editUser.is_receive_mail = res.data.is_receive_mail  === 1 ? true : false
          store.commit("setName", res.data.name);
          console.log("api取得", res.data);
          return res;
        })
        .catch((error) => {
          console.log("エラー:", error);
          return error.response;
        });
    };
    const fetchCustomersMe = () => {
      return http
        .get("/api/customers/me")
        .then((res) => {
          state.customer.end_at = res.data.end_at;
          console.log("api取得", res.data);
          return res;
        })
        .catch((error) => {
          console.log("エラー:", error);
          return error.response;
        });
    };
    const fetchPaymentHistoriesMe = () => {
      return http
          .get("/api/payment-histories/me")
          .then((res) => {
            paymentHistoryList.value = res.data;
            paymentHistoryList.value.forEach((el) => {
              el.active = false;
            });
            paymentHistoryList.value[0].active = true;
            return res;
          })
          .catch((error) => {
            console.log("エラー:", error);
            return error.response;
          });
    };
    const toggleAccordion = (num, flag) => {
      paymentHistoryList.value[num].active = !flag;
    };
    const downloadPdf = (id) => {
      const array = [];
      array.push(...paymentHistoryList.value);
      const targetObject = array.filter((el) => {
        return el.id === id;
      });
      http
        .get(`/api/payment-histories/receipt/download/?ids=${id}`, {
          responseType: "blob",
        })
        .then((res) => {
          const fileName = `${display.toYm(targetObject[0].paid_at)}分_ジッセン!DX領収書.pdf`;
          const blob = new Blob([res.data], {
            type: "application/pdf",
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.download = fileName;
          link.href = url;
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };
    const downloadZip = (array) => {
      http
        .get(`/api/payment-histories/receipt/download/?ids=${array}`, {
          responseType: "blob",
        })
        .then((res) => {
          const fileName = `ジッセン!DX領収書.zip`;
          const blob = new Blob([res.data], {
            type: "application/zip",
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.download = fileName;
          link.href = url;
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };
    const download = (id) => {
        downloadPdf(id);
    };
    const downloadAll = (obj) => {
      const array = [];
      obj.forEach((el) => {
        array.push(el.id);
      });
      if (array.length > 1) {
        downloadZip(array);
      } else {
        downloadPdf(array[0]);
      }
    };
    const init = () => {
      console.log("init");
      fetchUsersMe();
      fetchCustomersMe();

      if (servicePlanId.value == 1) {
        fetchPaymentHistoriesMe();
      }
    };

    watch(state.editUser, (obj) => {
      console.log(state.editUser.checkRequire);
      if (obj.name != "" && obj.name_kana != "" && obj.email != "") {
        state.editUser.checkRequire = false;
      } else {
        state.editUser.checkRequire = true;
      }
    });

    watch(
      () => store.state.user.isLogin,
      async () => {
        if (!isLogin.value) {
          router.push("/");
          return;
        }
      }
    );

    watch(
      () => store.state.user.isEnterprise,
      async () => {
        init();
      }
    );

    onMounted(async () => {
      if (!isLogin.value) {
        router.push("/");
        return;
      }
      
      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングで初期処理を行う
        console.log("skip on-mount process");
        return;
      }

      init();
    });

    const clickAddressCheck = () => {
      state.addressCheck = !state.addressCheck;
      if (!state.addressCheck) {
        state.editUser.email = state.nowEmail;
      }
    };

    const submit = () => {
      http
        .post("/api/users/me/update", {
          company_name: state.editUser.company_name,
          name: state.editUser.name,
          name_kana: state.editUser.name_kana,
          email: state.editUser.email,
          industry_type_id: state.editUser.industry_type_id,
          old_password: state.editUser.old_password,
          new_password: state.editUser.new_password,
          is_receive_mail: state.editUser.is_receive_mail  === true ? 1 : 0
        })
        .then((res) => {
          state.editUser.company_name = res.data.company_name;
          state.editUser.name = res.data.name;
          state.editUser.name_kana = res.data.name_kana;
          state.editUser.email = res.data.email;
          state.editUser.industry_type_id = res.data.industry_type_id;
          store.commit("setName", res.data.name);
          state.showAlert = true;

          window.setTimeout(function () {
            state.showAlert = false;
            document.body.style.overflow = "";
            router.push("/");
          }, 5000);
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          if (error.config && error.response && error.response.status === 400) {
            state.errors = error.response.data.errors;
          }
        });
    };

    return {
      store,
      state,
      isEnterprise,
      tabClick,
      clickAddressCheck,
      submit,
      paymentHistoryList,
      toggleAccordion,
      download,
      downloadAll,
      display,
      servicePlanId,
    };
  },
});
</script>

<style lang="scss" scoped>
.content-header {
  @include mq(md) {
    padding: 80px 20px;
  }
}
.hdg-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(md) {
    display: block;
  }
}
.tab-action {
  @include mq(sm) {
    margin-top: 60px;
  }
}
.content-body {
  position: relative;
  margin-top: -221px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  z-index: 1;
  @include mq(md) {
    margin-top: -590px;
  }
  @include mq(sm) {
    margin-top: -128px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
main {
  &::v-deep .force-pb-0 {
    .panel-body {
      padding-bottom: 0;
    }
  }
}
.panel-body {
  margin-bottom: 50px;
  padding: 65px;
  background-color: $white;
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  backdrop-filter: blur(10px);
  @include mq(sm) {
    padding: 60px 30px;
  }
}
.edit-profile {
  margin-top: 65px;
}
.edit-form {
  margin-top: 65px;
}
input[type="email"] {
  background-color: #f0f3f8;
}
.is-active {
  input[type="email"] {
    background-color: #ffffff;
  }
}
.payment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
}
.payment-action {
  display: flex;
  align-items: center;
  @include mq(sm) {
    align-items: stretch;
    margin-top: 40px;
    font-size: 13px;
  }
}
.payment-action-button {
  padding: 20px 30px;
  @include mq(sm) {
    width: 50%;
    padding: 10px;
    text-align: center;
  }
  &:first-of-type {
    margin-right: 37px;
    @include mq(sm) {
      margin-right: 0;
    }
  }
  &--black {
    background-color: $black;
    color: $white;
  }
  &--grey {
    background-color: $grey;
    color: $primary;
  }
}
.icon-arrow {
  display: none;
  @include mq(sm) {
    display: block;
    width: 22px;
    height: 10px;
    margin: 5px auto 0;
  }
}
.icon-download {
  display: inline;
  width: 20px;
  height: 20px;
  margin-left: 20px;
  @include mq(sm) {
    display: block;
    margin: 5px auto 0;
  }
}
.main-campaign {
  line-height: 1.7;
  margin-top: 20px;
  margin-left: 40px;
  color: #595857;
  @include mq(sm) {
    margin-top: 10px;
  }
}
</style>
