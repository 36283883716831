<template>
  <div class="payment-history-table">
    <div
      v-for="(item, num) in paymentHistoryList"
      :key="num"
      class="payment-history-item border-t"
      :class="item.active ? 'is-active' : ''"
    >
      <div class="payment-history-item-content">
        <p
          @click="toggleAccordion(num)"
          class="heading-accordion-button"
          :class="item.active ? 'is-active' : ''"
        ></p>
        <div class="column billing-number">
          <p class="label">請求番号</p>
          <p class="content">{{ item.order_id }}</p>
        </div>
        <div class="column billing-date mobile-first">
          <p class="label">請求日</p>
          <p class="content">{{ display.toDate(item.paid_at) }}</p>
        </div>
        <div class="column contract-plan">
          <p class="label">プラン</p>
          <p class="content">
            {{ item.plan_name === "プレミアム" ? "PREMIUM" : "BUSINESS" }}
          </p>
        </div>
        <div class="column target-period">
          <p class="label">対象期間</p>
          <p class="content">{{ item.from_on }}～{{ item.to_on }}</p>
        </div>
        <div class="column amount-of-money">
          <p class="label">金額</p>
          <p class="content">¥{{ Number(item.amount).toLocaleString() }}</p>
        </div>
        <div class="column download-action" @click="download(item.id)">
          <p class="label sp">領収書</p>
          <p class="content sp">
            Download<icon name="download-grey" class="icon-download sp"></icon>
          </p>
          <icon name="download-grey" class="icon-download pc"></icon>
        </div>
      </div>
    </div>
    <div class="attention-block">
      <p>注: 請求履歴は過去1年分のみ表示されます。</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import display from "@/utils/display";
export default defineComponent({
  name: "PaymentHistoryTable",

  components: {},
  props: {
    paymentHistoryList: Object,
  },
  setup(props, context) {
    const download = (id) => {
      context.emit("download", id);
    };

    const toggleAccordion = (num) => {
      const flag = props.paymentHistoryList[num].active;
      context.emit("toggleAccordion", num, flag);
    };
    return {
      display,
      download,
      toggleAccordion,
    };
  },
});
</script>

<style lang="scss" scoped>
.payment-history-table {
  margin-top: 65px;
  @include mq(sm) {
    margin-top: 30px;
  }
}
.payment-history-item {
  position: relative;
  margin-top: 0;
  padding: 50px 0;
  &::after {
    content: "";
    width: 30px;
    height: 4px;
    position: absolute;
    top: -2px;
    left: 0;
    background-color: $black;
    z-index: 1;
  }
  @include mq(sm) {
    padding: 30px 0;
  }
  &:nth-of-type(2n) {
    position: relative;
    &:before {
      content: "";
      width: calc(100% + 130px);
      height: 100%;
      position: absolute;
      top: 50%;
      left: -65px;
      background-color: #f8fbfb;
      transform: translateY(-50%);
      z-index: -1;
    }
  }
  &:last-of-type {
    border-bottom: 1px solid $black;
  }
  &.is-active {
    .column {
      display: block;
    }
  }
}
.payment-history-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
  .column {
    position: relative;
    padding: 0 32px;
    @include mq(sm) {
      display: none;
      margin-top: 15px;
      padding: 15px 0 0 0;
    }
    &:first-of-type {
      padding-left: 0;
      &::before {
        @include mq(sm) {
          content: "";
          width: 20px;
          height: 1px;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $black;
          transform: translateY(-50%);
        }
      }
    }
    &.mobile-first {
      @include mq(sm) {
        display: block;
        order: -1;
        margin-top: 0;
        padding-top: 0;
        margin-bottom: -15px;
        padding-bottom: 15px;
        &:before {
          content: none !important;
        }
      }
    }
    &:last-of-type {
      padding-right: 0;
    }
    &:nth-of-type(n + 2) {
      &::before {
        content: "";
        width: 1px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        background-color: $black;
        transform: translateY(-50%);
        @include mq(sm) {
          top: 0;
          width: 20px;
          height: 1px;
        }
      }
    }
    .label {
      font-size: 12px;
      color: rgba(89, 88, 87, 0.8);
    }
    .content {
      margin-top: 5px;
      @include mq(sm) {
        display: block;
      }
    }
  }
}
.icon-download {
  width: 20px;
  height: 20px;
  cursor: pointer;
  @include mq(sm) {
    display: none;
  }
  &.sp {
    @include mq(sm) {
      width: 15px;
      height: 15px;
      display: inline;
      margin-left: 10px;
      vertical-align: baseline;
    }
  }
}
.attention-block {
  position: relative;
  padding: 15px 0;
  background-color: $black;
  &:before {
    content: "";
    width: calc(100% + 130px);
    height: 100%;
    position: absolute;
    top: 50%;
    left: -65px;
    background-color: $black;
    transform: translateY(-50%);
    z-index: -1;
    @include mq(sm) {
      width: calc(100% + 60px);
      left: -30px;
    }
  }
  p {
    color: #ffffff;
    font-size: 14px;
  }
}
.sp {
  display: none;
  @include mq(sm) {
    display: block;
  }
}

.heading-accordion-button {
  display: none;
  @include mq(sm) {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 20px;
    width: 1px;
    background-color: $black;
    transform: translateY(-50%);
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 20px;
    background-color: $black;
    transform: translateX(-50%);
  }
  &.is-active {
    top: 50px;
    background-color: #3683f5;
    box-shadow: 0px 20px 30px rgba(54, 131, 245, 0.25);

    &:before {
      content: none;
    }
    &::after {
      background-color: #ffffff;
    }
  }
}
</style>